import { React } from "react";
import Footer from "../components/Footer";
import ServicesPage from "../components/ServicesPage";
import Navbar from "../components/Navbar";

const service = [
  "DAO formation",
  "Wyoming DAO LLC",
  "Blockchain related ltigation",
  "DMCA takedown notices",
];

const benefit = [
  "13 years in business",
  "Started by IP Lawyers",
  "300 + crypto/NFT clients",
  "Litigation in federal & state",
];

export default function Services() {
  return (
    <>
    <Navbar />
      <ServicesPage
        heading1="BLOCKCHAIN"
        heading2="AND DAO"
        services={service}
        benefits={benefit}
      />
      <Footer />
    </>
  );
}
