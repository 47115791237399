import { React, useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Sections from "../images/Sectionsbg.png";
import Address from "../images/Address.svg";
import Person from "../images/Person.svg";
import emailjs from "emailjs-com";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const accordionData = [
  {
    title: "What is Enforceable ",
    content: `Enforceable is a Web3 law firm based in Silicon Valley, and a brand of LegalForce RAPC Worldwide P.C., the law firm that created the Trademarkia.com website.  The firm is the largest trademark filing law firm in the United States.`,
  },
  {
    title: "What does Enforceable do?",
    content: `We help brand owners, NFT startups, and metaverse companies protect and enforce their IP rights.`,
  },
  {
    title: "What are your legal fees?",
    content: `We offer both fixed feen and project based work.  Our fees can be paid in crypto or US currency.  Our hourly billing rate is $400 an hour, though we also work on project fixed fees as well.`,
  },
];

export default function Faq() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
  const TEMPLATE_ID2 = process.env.REACT_APP_TEMPLATE_ID2;
  const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;
  console.log(SERVICE_ID)
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { firstName, lastName, email, message };
    try {
      emailjs
        .sendForm(SERVICE_ID, TEMPLATE_ID2, e.target, PUBLIC_KEY)
        .then(
          (toast.success("Thank you for your message!"),
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        )
        .then((result) => {
          console.log(result);
          setEmail("");
          setMessage("");
          setFirstName("");
          setLastName("");
        });
    } catch (err) {
      toast.error(err, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <>
      <Navbar />
      <div
        className="relative sections-img"
        // style={{ backgroundImage: `url(${Sections})` }}
      >
        <div className="max-w-full lg:mb-64 xs:mb-32 lg:ml-20">
          <div className="relative z-10 pb-8 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-2/3 lg:pb-28 xl:pb-32">
            <main className=" mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-48 lg:mt-32 xs:mt-24 lg:text-left">
              <div className=" lg:text-left">
                <h1 className="text-4xl tracking-wide font-semibold text-white sm:text-5xl md:text-6xl xs:text-3xl xs:pt-4 xs:pb-2 md:pt-12">
                  <span className="block xl:inline text-shadow">CONTACT US</span>{" "}
                </h1>
                <p className="text-base font-black text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-2xl lg:mx-0 lg:mt-12 lg:mb-4 xs:py-3 xs:mt-6 xs:text-left md:ml-2">
                  Enforceable / Trademarkia
                </p>
                <div className="flex">
                  <img src={Address} alt="address" className="w-8 h-8 mr-8 mt-2" />
                <p
                  className=" text-white font-thin sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0 lg:mt-8 xs:text-sm xs:text-left md:ml-2"
                  style={{ marginTop: "2px" }}
                >
                  1580 West El Camino Real Mountain VIew CA 94040
                  <br></br>
                  877-794-9511 (Toll Free U.S. / Canada)
                  <br></br>
                  +44 203 300 0125 (Europe)
                  <br></br>
                  650 390 6400 (U.S. Local / International)
                </p>
                </div>
                
                <p className="text-base text-white font-black sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-2xl lg:mx-0 lg:mt-12 lg:mb-4  xs:py-3 xs:mt-6 xs:text-left md:ml-2">
                  Contact Lead
                </p>
                <div className="flex">
                  <img src={Person} alt="person" className="w-8 h-8 mr-8 mt-2" />
                <p
                  className="text-base text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0 lg:mt-12 xs:text-sm xs:text-left md:ml-2"
                  style={{ marginTop: "2px" }}
                >
                  raj@enforceable.com,
                  <br></br>
                  Raj Abhyanker IP attorney
                </p>
                </div>
              </div>
            </main>
          </div>
        </div>
        <div className="mt-24 xl:absolute lg:inset-y-0 lg:right-96 lg:w-3/8 lg:align-center lg:justify-center lg:flex">
          <div class="mr-24 lg:w-96 md:w-full mx-auto">
            <div
              class="card p-6 border border-gray-600 sm:rounded-md"
              style={{ background: "rgba(0, 0, 0, 0.29)" }}
            >
              <form onSubmit={handleSubmit}>
                <a className="w-full flex items-center justify-center px-8 font-semibold rounded-md text-textGreen md:py-4 md:text-md md:px-10 lg:text-lg whitespace-nowrap">
                  Let us know what you think
                </a>
                <a
                  className="w-full flex items-center justify-center px-8 py-3 font-bold rounded-md text-white md:py-4 md:text-md md:px-10 lg:text-3xl lg:-mt-2"
                  // style={{ marginTop: "-20px" }}
                >
                  Send us a message
                </a>
                <a
                  className="w-full flex items-center justify-center xs:mt-2 px-8 py-3 font-medium rounded-md text-white md:py-4 md:text-md md:px-10 lg:text-lg xs:mb-8 lg:mb-6"
                  style={{ marginTop: "-20px" }}
                >
                  Enter your details here
                </a>
                <label class="block mb-6 p-2 px-12  lg:px-12 xs:p-0">
                  <span class="text-gray-300 text-md ">First Name</span>
                  <input
                    name="firstName"
                    type="text"
                    class="
                  block w-full mt-1
                  border-2
                  bg-formBg
                  rounded-full
                  shadow-sm
                  border-borderColor
                  focus:border-white
                  bg-transparent
                  placeholder-gray-600
                  text-white
                  p-4
          "
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </label>
                <label class="block mb-6 p-2 px-12  lg:px-12 xs:p-0">
                  <span class="text-gray-300 text-md ">Last name</span>
                  <input
                    name="lastname"
                    type="text"
                    class="
                  block w-full mt-1
                  border-2
                  bg-formBg
                  rounded-full
                  shadow-sm
                  border-borderColor
                  focus:border-white
                  bg-transparent
                  placeholder-gray-600
                  text-white
                  p-4
          "
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </label>
                <label class="block mb-6 p-2 px-12  lg:px-12 xs:p-0">
                  <span class="text-gray-300 text-lg">Email address</span>
                  <input
                    name="email"
                    type="email"
                    class="
                  block w-full 
                  border-2
                  bg-formBg
                  rounded-full
                  shadow-sm
                  border-borderColor
                  focus:border-white
                  placeholder-gray-600
                  text-white
                  p-4
          "
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </label>
                <label class="block mb-6 p-2 px-12  lg:px-12 xs:p-0">
                  <span class="text-gray-300 lg:text-lg xs:text-sm ">
                    What question can we answer for you
                  </span>
                  <textarea
                    name="message"
                    type="text"
                    class="block w-full mt-1
                  border-2
                  bg-formBg
            rounded-lg
            shadow-sm
            border-borderColor
            focus:border-white
            placeholder-gray-600
            text-white
            p-4
            resize-none
          "
                    rows="5"
                    placeholder="Tell us what you're thinking about..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </label>

                <div class="mb-4 p-2 px-6 flex items-center justify-center">
                <button
                type="submit"
                class="
            h-12
            lg:px-24
            text-white
            text-lg
            bg-buttonGreen
            font-semibold
            rounded-3xl
            transition-colors
            duration-150
            focus:shadow-outline
            hover:bg-white
            hover:text-black
            xs:px-16
                          
          "
              >
                Submit
              </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <ToastContainer />
    </>
  );
}
