import {React,useState} from "react";
import Logo from "../images/CompanyLogo.png";
import emailjs from "emailjs-com";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { recaptchaClient } from "../config/axiosClient";


export default function Footer() {
  const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
  const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
  const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;
  const[email, setEmail] = useState("");

  const createNewsletter = async (email) => {
    const body = {
        email: email,
    };
    await recaptchaClient.post("/form/newsletter", body);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("b")
    createNewsletter(email).then(() => {
      toast.success("You have successfully subscribed to our newsletter 🎯");
    }).catch(() => {
      toast.error("Oops, something went wrong. Please try again 😢");
      console.log(email)
    console.log(recaptchaClient)
    });
    // resetForm();
  }
  //   try {
  //     emailjs
  //       .sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY)
  //       .then(
  //         (toast.success("Thank you for your message!"),
  //         {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         })
  //       )
  //       .then((result) => {
  //         console.log(result);
  //       });
  //   } catch (err) {
  //     toast.error(err, {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   }
  // };


  return (
    <>
    <footer class="relative mt-12 mb-8 lg:ml-8 p-4 sm:p-6 bg-footerBg">
      <div class="md:flex md:justify-between xs:mr-8">
        <div class="mb-6 md:mb-0 w-1/2 ml-32 sm:w-full sm:ml-4 sm:pr-10 xs:w-full xs:ml-4 lg:ml-24 lg:mr-24 ">
          <a href="#" class="flex items-center mb-4">
            <img src={Logo} class="mr-3 h-16 sm:mr-0 xs:h-12" alt="Enforceable Logo" />
          </a>
          <a class="text-white lg:text-base xs:text-xs flex-wrap" style={{lineHeight: "24pt"}}>
            A crypto-native law practice servicing the diverse needs to NFT and
            metaverse cerators, marketplaces, and blockchain digital asset
            owners. Enforceable is a DBA of LegalForce RAPC Worldwide P.C., a
            law firm in Mountain View, CA.
          </a>

          <ul class="text-white text-sm">
            <li class="mt-6 my-4">
              <a href="#" class="hover:font-semibold">
                Terms
              </a>
            </li>
            <li class="my-4">
              <a href="#" class="hover:font-semibold">
                Privacy Policy
              </a>
            </li>
            <li class="my-4">
              <a href="#" class="hover:font-semibold">
                Contact Us
              </a>
            </li>
          </ul>
        </div>
        <div class="mb-6 md:mb-0 w-1/2 ml-32 sm:w-full sm:ml-4 sm:pr-10 xs:w-full xs:ml-4">
          {/* <a href="#" class="flex items-center">
            <img src={Logo} class="mr-3 h-16" alt="Enforceable Logo" />
          </a> */}
          <a href="" class="flex items-center text-lg text-white font-semibold mt-16 mb-6 ">
          Stay In The Loop
          </a>
          <a href="" class="text-white mt-16 xs:pr-30 xs:text-xs xs:mt-4 flex-wrap lg:text-base">
            Join our mailing list to receive valuable insights in the world of
            NFTs and the intellectual property rights affecting them.
          </a>

          <div class="pt-6">
            <form className="flex" onSubmit={handleSubmit}>
              <div class="lg:grid md:grid-cols-2 grid-cols-1 gap-4 flex justify-center items-center xs:contents">
                <div class="md:mb-6">
                  <input
                    name="email"
                    // onChange={(e) => {e.preventDefault()}}
                    type="email"
                    value={email}
                  onChange={(e) => setEmail(e.target.value)}
                    class="
              form-control
              block
              lg:w-full
              px-4
              py-1.5
              text-base
              font-normal
              text-white
              bg-clip-padding
              xs:w-11/12
              rounded-lg
              transition
              ease-in-out
              m-0

              focus:text-white focus:bg-white focus:border-white focus:outline-none
            "
                    placeholder="Email address"
                    style={{ backgroundColor: "rgba(255, 255, 255, 0.18)" }}

                    required
                  />
                </div>

                <div class="md:mr-auto mb-6">
                  <button
                    type="submit"
                    class="inline-block px-6 py-2.5 text-white font-medium text-xs leading-tight uppercase rounded-lg shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out xs:whitespace-nowrap"
                    style={{backgroundColor: "#4C76B4"}}
                  >
                    Sign Up
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </footer>
    <ToastContainer />
    </>
  );
}
