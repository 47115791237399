import { React } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import CertificationSection from "../components/CertificationSection";

const service = [
  "eTrademark your NFT Collection",
  "Copyright your NFT",
  "Creator Declarations",
  "Work for hire agreements",
  "Licensing Agreements",
  "Real Estate NFTs",
  "LLC Asset wrappers",
  "Incorporation services",
  "Access / Membership NFT agreements",
  "Wyoming DAO LLC formation",
];

const benefit = [
  "13 years in business",
  "Started by IP Lawyers",
  "300 + crypto/NFT clients",
  "Litigation in federal & state",
];

export default function Services() {
  return (
    <>
      <Navbar />
      <CertificationSection
        heading1="SECURITZE NFTs WITH COPYRIGHT ASSIGNMENTS"
        services={service}
        benefits={benefit}
      />
      <Footer />
    </>
  );
}
