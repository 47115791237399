import { React } from "react";
import Footer from "../components/Footer";
import ServicesPage from "../components/ServicesPage";
import Navbar from "../components/Navbar";

const service = [
  "DMCA to OpenSea",
  "Artist defense",
  "Federal Lawsuits we",
  "Mediation for NFTs",
  "Arbitration for NFTs",
  "Class Actions",
];

const benefit = [
  "13 years in business",
  "Started by IP Lawyers",
  "300 + crypto/NFT clients",
  "Litigation in federal & state",
];

export default function Services() {
  return (
    <>
    <Navbar />
      <ServicesPage
        heading1="LITIGATION"
        heading2="SERVICES"
        services={service}
        benefits={benefit}
      />
      <Footer />
    </>
  );
}
