import React, { useState } from 'react';
import './styles/faq.css';

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className="accordion-item">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)} >
        <div>{title}</div>
        <div>{isActive ? '-' : '+'}</div>
      </div>
      
      {isActive && <div className="accordion-content">{content}</div>}
      <hr />
    </div>
  );
};

export default Accordion;