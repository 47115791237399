import { React, useEffect,useState } from "react";
import HeaderSection from "./HeaderSection";
import Aos from "aos";
import "aos/dist/aos.css";
import Step1 from "../images/Step3.png";
import NFT from "../images/NFT.svg";
import No3 from "../images/No3.svg";
import "./styles/faq.css"

export default function Steps() {
  const [componentHide, setComponentHide] = useState(false);
  const changeComponentVisibility = () =>{
    if(window.scrollY >= 1000){
      setComponentHide(false);
    }
    else{
      setComponentHide(false);
    }
  }
  window.addEventListener('scroll', changeComponentVisibility);
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div
        className=" w-full lg:mt-32 xs:mt-0 lg:mb-48"
        // className={componentHide ? 'comp w-full mt-24 componentHide' : 'comp w-full mt-2 mb-24'}
        // style={{ height: "60vh" }
        // style={{ height: "40vh" }}
        // data-aos="fade-up"
        // data-aos-anchor-placement="top-bottom"

      >
        <div className="flex justify-between" style={{ marginLeft: "20", width: "350" }}>
          <div className="grid">
            <img src={NFT} alt="NFT" className="w-20 h-20 mt-1 step-img" />
            <a className="font-semibold text-left rounded-md text-white md:py-4 md:text-md lg:text-4xl step-img" style={{marginTop:"-30px"}}>
              Easy as 1, 2, 3
            </a>
            <div className="flex justify-center align-center mb-4 mt-2">
                <img
                  src={Step1}
                  alt="Step 1"
                  className="lg:hidden md:hidden sm:hidden xs:w-36 xs:h-36"
                />
                </div>
            <div className="flex lg:mt-20">
              <img src={No3} alt="No1" className="lg:w-40 lg:h-40 xs:w-12 numbers xs:h-auto lg:mr-6 xs:mr-6 xs:mt-3"/>
              
              <div className="text-left mr-48 xs:mr-0 xs:mt-5">
              <a className="font-semibold rounded-md text-white md:py-4 md:text-md lg:text-4xl lg:mb-24 xs:text-2xl">
              Mint NFT
                </a>
                {/* Sub heading */}
                <p className="lg:mt-6 lg:text-lg text-medium text-white text-left xs:text-sm xs:mt-2 xs:font-thin">
                Mint to popular Blockchains and save money. Authorship and IP rights get minted on-chain. Sell your NFTs on any marketplace!
                </p>
              </div>
            </div>
          </div>
          <img alt="test" className="lg:w-72 lg:h-72 mt-16 md:h-40 md:w-40 xs:w-24 xs:h-24 step-img lg:ml-64 md:ml-4" src={Step1}  />
        </div>
      </div>
    </>
  );
}
