import { React } from "react";
import Footer from "../components/Footer";
import CertificationSection from "../components/CertificationSection";
import Navbar from "../components/Navbar";

const service = [
  "eNFT badges",
  "Forensic audit services",
  "Monitoring services",
  "LLC formation",
  "Sell your home via NFT",
];

const benefit = [
  "13 years in business",
  "Started by IP Lawyers",
  "300 + crypto/NFT clients",
  "Litigation in federal & state",
];

export default function Services() {
  return (
    <>
    <Navbar />
      <CertificationSection
        heading1="CERTIFICATION"
        heading2="SERVICES"
        services={service}
        benefits={benefit}
      />
      <Footer />
    </>
  );
}
