import axios from "axios";
import { React, useEffect, useState } from "react";
import Footer from "../components/Footer";
import HeaderSection from "../components/HeaderSection";
import Hero from "../components/Hero";
import Kinds from "../components/Kinds";
import NewsCard from "../components/NewsCard";
import ServicesSection from "../components/ServicesSection";
import Bg from "../images/Bg.png";
import E from "../images/E.svg";
import Steps from "../components/Steps";
import Step2 from "../components/Step2";
import Step3 from "../components/Step3";
import NewsCarousel from "../components/NewsCarousel";
import FormCard from "../components/FormCard";
import Navbar from "../components/Navbar";

export default function Landing() {

  const url = "https://api.enforceable.com/api/v1/news/all";
  const [news, setNews] = useState([]);

  useEffect(() => {
    axios
      .get(url)
      .then((res) => {
        setNews(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  if (news.body == null) {
    return (
      <div className="flex mx-auto my-auto justify-center align-center items-center" style={{height: "100vh"}}>
          <img className="animate-bounce h-16 w-16 mx-auto" src={E} alt="loading" />

        </div>
    );
  }
  return (
    <>
    <Navbar />
      <Hero />
      {/* <ServicesPage heading1= "Litigation" heading2= "Dispute Resolution" services={service} benefits={benefit}/> */}
      <HeaderSection />
      <div className="landing-bg" style={{ backgroundImage: `url(${Bg})` }}>
        <div className="comp overflow-x-hidden overflow-y-scroll lg:mb-36 lg:mt-28 xs:mt-16 xs:mb-10">
      <div className="max-w-7xl mt-24 mx-auto  text-center flex justify-center inline-block px-4 sm:px-6 lg:px-8">
          <Steps />
        </div>
        <div className="max-w-7xl lg:mt-24 xs:mt-32  mx-auto text-center flex justify-center inline-block px-4 sm:px-6 lg:px-8">
          <Step2 />
        </div>
        <div className="max-w-7xl lg:mt-24 xs:mt-32  mx-auto text-center flex justify-center inline-block px-4 sm:px-6 lg:px-8">
          <Step3 />
        </div>
        
        </div>
        <div className="max-w-7xl lg:mt-24 xs:mt-20 mx-auto text-center flex justify-center inline-block px-4 lg:px-8">
          <Kinds />
        </div>
        
        
        <ServicesSection />
        <div className="flex justify-center lg:mt-8 xs:-mt-24">
        <div className="max-w-7xl mt-24 text-center flex justify-center text-center inline-block px-4 sm:px-6 lg:px-8">
          <div className="lg:mb-24 xs:mb-12">
            <p className="mt-2 lg:text-4xl leading-8 font-medium tracking-wide text-white sm:text-4xl xs:text-xl">
              CERTIFY YOUR NFTs WITH A <span className="font-black">TRUST BADGE</span>
            </p>
            <p className="mt-4 lg:text-lg text-lightText xs:text-sm xs:whitespace-nowrap">
              Recent news highlights the need for Enforceable
            </p>
          </div>
        </div>
        </div>
        <div className="lg:px-12">
        <NewsCarousel />
        </div>
        {/* <div className="flex flex-wrap justify-center mx-48 overflow-x-auto ">
          {news.body.map((news) => (
            <NewsCard
              article_url={news.article_url}
              image_url={news.image_url}
              headline={news.headline}
            />
          ))}
        </div> */}
        <div className="max-w-7xl mt-24 flex justify-center inline-block md:px-24 sm:px-8 xs:px-2 lg:px-8">
          {/* <div className="lg:mb-24 xs:mb-12"> */}
          <FormCard />
          {/* </div> */}
        </div>
        
      </div>
      
      <Footer />
      {/* <div className="accordion" >
              {accordionData.map(({ title, content }) => (
                <FAQAccordion title={title} content={content} />
              ))}
            </div> */}
    </>
  );
}
