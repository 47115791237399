import {React, useState} from "react";
import './styles/faq.css';
import emailjs from 'emailjs-com';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

export default function FormCard() {
  const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
  const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
  const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  console.log(SERVICE_ID)
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { name, email, message };
    console.log(data);
    try {
      emailjs
        .sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY)
        .then(
          (toast.success("Thank you for your message!"),
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        )
        .then((result) => {
          console.log(result);
          setEmail("");
          setMessage("");
          setName("");
        });
    } catch (err) {
      toast.error(err, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <>
    <div
      class="xl:hidden lg:hidden md:block mx-auto w-11/12 p-6 border border-borderColor sm:rounded-md mb-12 "
      style={{ background: "rgba(0, 0, 0, 0.4)" }}
    >
      <form onSubmit={handleSubmit}>
        <a className="w-full flex items-center text-center text-lg justify-center font-medium mt-6 text-textGreen whitespace-nowrap">
          Question for Enforceable?
        </a>
        <a className="text-help w-full flex items-center text-center justify-center sm:px-0 font-semibold rounded-md text-white mb-8 pt-2 text-sm">
          Let us see if we can help
        </a>
        <label class="block mb-6 p-2 px-12 mt-2 px-12 xs:p-0">
          <span class="text-gray-300 text-sm ">
            What question can we answer for you : 
          </span>
          <textarea
            name="message"
            type="text"
            class="block w-full mt-1
            border-2
      rounded-xl
      shadow-sm
      border-borderColor
      focus:border-white
      bg-transparent
      placeholder-gray-600
      text-white
      p-4
      resize-none
    "
            rows="5"
            
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
        </label>
        <label class="block mb-8 p-2 px-12   px-12 xs:p-0">
          <span class="text-gray-300 text-sm ">Your name</span>
          <input
            name="name"
            type="text"
            class="
            block w-full mt-1
            border-2
            border-borderColor
            rounded-full
            shadow-sm
            bg-transparent
            placeholder-gray-600
            text-white
            p-4
    "
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </label>
        <label class="block mb-6 p-2  px-12 xs:p-0">
          <span class="text-gray-300 text-sm">Email address</span>
          <input
            name="email"
            type="email"
            class="
            block w-full 
            border-2
            border-borderColor
            rounded-full
            shadow-sm
            focus:border-white
            bg-transparent
            placeholder-gray-600
            text-white
            p-4
    "
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </label>

        <div class="m-2 p-2 px-6 flex items-center justify-center">
          <button
            type="submit"
            class="
      h-10
       px-48
      text-black
      text-sm
      bg-white
      font-semibold
      rounded-full
      transition-colors
      duration-150
      focus:shadow-outline
      hover:bg-buttonGreen
      hover:text-white
      xs:px-16
                    
    "
          >
            Submit
          </button>
        </div>
      </form>
    </div>
    <ToastContainer />
    </>
  );
}
