import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Landing from "./pages/Landing";
import Services from "./pages/Services";
import Faq from "./pages/Faq";
import Contact from "./pages/Contact";
import Blockchain from "./pages/Blockchain";
import Certification from "./pages/Certification";
import Metaverse from "./pages/Metaverse";
import Litigation from "./pages/Litigation";
import Intellectual from "./pages/Intellectual";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/services" element={<Services />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services/blockchain" element={<Blockchain />} />
        <Route path="/services/certification" element={<Certification />} />
        <Route path="/services/metaverse" element={<Metaverse />} />
        <Route path="/services/litigation" element={<Litigation />} />
        <Route path="/services/intellectual" element={<Intellectual />} />
      </Routes>
    </Router>
  );
}

export default App;
