import { React, useState } from "react";
import kind1 from "../images/kind1.png";
import kind2 from "../images/kind2.png";
import kind3 from "../images/kind3.png";
import kind4 from "../images/kind4.png";
import kind5 from "../images/kind5.png";

const tabs = [
  {
    title: "NFT Art",
    icon: kind1,
  },
  {
    title: "Photography",
    icon: kind2,
  },
  {
    title: "Fine Art",
    icon: kind3,
  },
  {
    title: "Lifestyle Brand",
    icon: kind4,
  },
  {
    title: "Talent Rep / Producer",
    icon: kind5,
  },
];

export default function Kinds() {
  const [active, setActive] = useState(tabs[0]);

  return (
    <div className="lg:container text-center xs:overflow-x-scroll xs:overflow-y-hidden lg:overflow-hidden md:overflow-hidden">
      <div className="mt-2 lg:text-4xl leading-8 font-medium tracking-wide text-white xs:text-2xl">
        <h1>YOU ARE <span className="font-black">ONE OF A KIND</span></h1>
      </div>
      <div className="tabs">
        {tabs.map((tab, index) => (
          <div
            className={"tab mt-4 max-w-2xl lg:text-base text-lightText xs:text-xs" + (active === tab ? " active" : "")}
            key={index}
            onClick={() => setActive(tab)}
          >
            {tab.title}
          </div>
    
        ))}
        
      </div>
      {tabs.map((tab, index) => (
          <div
            className={"tab hero" + (active === tab ? " img-active" : "")}
            key={index}
            onClick={() => setActive(tab)}
          >
            <img src={tab.icon} alt={tab.title}/>
          </div>
    
        ))}
      
    </div>
  );
}
