import { Popover, Transition } from "@headlessui/react";
import { React, Fragment, useState } from "react";
import CompanyLogo from "../images/CompanyLogo.png";
import Facebook from "../images/Facebook.svg";
import Instagram from "../images/Instagram.svg";
import Twitter from "../images/Twitter.svg";
import Secure from "../images/Secure.svg";
import CertificationIcon from "../images/CertificationIcon.svg";
import MetaverseIcon from "../images/MetaverseIcon.svg";
import IntellectualIcon from "../images/IntellectualIcon.svg";
import LitigationIcon from "../images/LitigationIcon.svg";
import BlockchainIcon from "../images/BlockchainIcon.svg";
import {ReactComponent as Services} from "../images/Services.svg";
import {ReactComponent as Help} from "../images/help.svg";
import {ReactComponent as Contact} from "../images/contact.svg";
import Drop from "../images/drop.svg";
import Menu from "../images/Menu.svg";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { ReactComponent as Drop2 } from "../images/drop.svg";
import Discord from "../images/Discord.svg";

const navigation = [
  // { name: "Services", href: "/services" },
  { name: "FAQ", href: "/faq", icon: Help },
  { name: "Contact Us", href: "/contact", icon: Contact },
];

export default function Navbar({ fixed }) {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const redirectFAQ = () => {
    window.location.href = "/faq";
  }
  const redirectContact = () => {
    window.location.href = "/contact";
  }
  const navbarOpenHandler = () => {
    setNavbarOpen(!navbarOpen);
  };
  const navbarCloseHandler = () => {
    setNavbarOpen(false);
  };
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 200) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  return (
    <>
      {!navbarOpen ? (
        <nav class={colorChange? "fixed w-full z-20 px-2 sm:px-4 py-2.5 bg-black" : "fixed w-full z-20 px-2 sm:px-4 py-2.5 bg-blackTransparent" }>
          <div class="flex flex-wrap justify-between items-center mx-auto">
            <a href="/" class="flex items-center ml-4">
              <img src={CompanyLogo} class="mr-3 lg:h-12 xs:h-9" />
              <span class="self-center text-xl font-semibold whitespace-nowrap"></span>
            </a>
            <div class="flex items-center md:order-2">
              <button
                type="button"
                class="flex mr-3 text-sm rounded-full md:mr-0 "
                // id="user-menu-button"
                aria-expanded="false"
                // data-dropdown-toggle="dropdown"
              >
                <ul className="hidden lg:flex flex-col lg:flex-row list-none lg:ml-auto">
                  <li className="nav-item">
                    <a
                      className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                      href="https://twitter.com/EnforceableNFT"
                      target="_blank"
                    >
                      <img
                        src={Twitter}
                        className="mr-3 mt-1 h-6"
                        alt="Twitter"
                      />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                      href="https://discord.gg/Zj3tcyxGRv"
                      target="_blank"
                    >
                      <img
                        src={Discord}
                        className="mr-3 h-8"
                        alt="Discord"
                      />
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <a
                      className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                      href=""
                    >
                      <img src={Facebook} className="mr-3 h-6" alt="Facebook" />
                    </a>
                  </li> */}
                </ul>
              </button>
              <div className="-mr-2 flex items-center md:hidden">
                <Popover>
                  <Popover.Button
                    className="rounded-md p-2 inline-flex items-center justify-center text-white mr-4"
                    onClick={navbarOpenHandler}
                  >
                    <span className="sr-only">Open main menu</span>
                    {/* <MenuIcon className="h-6 w-6" aria-hidden="true" /> */}
                    <img src={Menu} alt="menu" className="h-6 w-6" />
                  </Popover.Button>
                </Popover>
              </div>
            </div>
            <div
              class=" justify-between items-center w-full md:flex md:w-auto md:order-1 xs:hidden"
              // id="mobile-menu-2"
            >
              <ul class="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
                <li>
                  <button
                    onClick={toggleDropdown}
                    class="block py-2 pr-4 text-lg pl-3 text-white drop hover:text-textGreen md:hover:text-textGreen md:border-0 md:hover:text-textGreen sm:hover:bg-hoverBg md:p-0"
                  >
                    <div className="flex">
                      {/* <img src={Services} alt="Services" className="mr-2" /> */}
                      <Services className="m-2" />
                      Services
                      {/* <img src={Drop} alt="Drop" className="ml-2 mt-1" /> */}
                      <span className="drop">
                      <Drop2 className="ml-2 mt-3" />
                      </span>
                    </div>
                    {isOpen && ( // if isOpen is true, show the dropdown
                      <Transition
                        show
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <div
                          className="absolute z-20 w-72 top-12 rounded-full origin-top-right shadow-lg"
                          style={{ borderRadius: "200px" }}
                        >
                          <div
                            className="shadow-xs"
                            style={{
                              backdropFilter: "blur(42.0842px)",
                              WebkitBackdropFilter: "blur(42.0842px)",
                              background: "rgba(82, 82, 82, 0.24)",
                            }}
                          >
                            <div className="flex pl-6 py-3 hover:bg-hoverState focus:outline-none focus:bg-hoverState transition ease-in-out duration-150">
                              <img src={Secure} alt="Services" />
                              <a
                                href="/services"
                                className="block px-4 py-3 text-lg leading-5 text-white whitespace-nowrap"
                              >
                                Securitizing NFTs
                              </a>
                            </div>
                            <div className="flex pl-6 py-4 hover:bg-hoverState focus:outline-none focus:bg-hoverState transition ease-in-out duration-150">
                              <img src={LitigationIcon} alt="Services" />
                              <a
                                href="/services/litigation"
                                className="block px-4 py-3 text-lg leading-5 text-white whitespace-nowrap"
                              >
                                Litigation Services
                              </a>
                            </div>
                            <div className="flex pl-6 py-4 hover:bg-hoverState focus:outline-none focus:bg-hoverState transition ease-in-out duration-150">
                              <img src={IntellectualIcon} alt="Services" />
                              <a
                                href="/services/intellectual"
                                className="block px-4 py-3 text-lg leading-5 text-white whitespace-nowrap"
                              >
                                Intellectual Property
                              </a>
                            </div>
                            <div className="flex pl-6 py-3 hover:bg-hoverState focus:outline-none focus:bg-hoverState transition ease-in-out duration-150">
                              <img src={BlockchainIcon} alt="Services" />
                              <a
                                href="/services/blockchain"
                                className="block px-4 py-3 text-lg leading-5 text-white whitespace-nowrap"
                              >
                                Blockchain / DAO
                              </a>
                            </div>
                            <div className="flex pl-6 py-3 hover:bg-hoverState focus:outline-none focus:bg-hoverState transition ease-in-out duration-150">
                              <img src={MetaverseIcon} alt="Services" />
                              <a
                                href="/services/metaverse"
                                className="block px-4 py-3 text-lg leading-5 text-white whitespace-nowrap"
                              >
                                Metaverse / VR Land
                              </a>
                            </div>
                            <div className="flex pl-6 hover:bg-hoverState focus:outline-none focus:bg-hoverState transition ease-in-out duration-150">
                              <img src={CertificationIcon} alt="Services" />
                              <a
                                href="/services/certification"
                                className="block px-4 pt-4 pb-6 text-lg leading-5 text-white whitespace-nowrap"
                              >
                                Certification Services
                              </a>
                            </div>
                          </div>
                        </div>
                      </Transition>
                    )}
                  </button>
                </li>
                <li>
                <button
                    onClick={redirectFAQ}
                    class="block py-2 lg:px-5 text-lg text-white drop hover:text-textGreen md:hover:text-textGreen md:border-0 md:hover:text-textGreen sm:hover:bg-hoverBg md:p-0"
                  >
                    <div className="flex">
                      {/* <img src={Services} alt="Services" className="mr-2" /> */}
                      <Help className="mr-2 mt-2" />
                      FAQ
                      {/* <img src={Drop} alt="Drop" className="ml-2 mt-1" /> */}
                    </div>
                    </button>
                  {/* <button className="drop">
                  <div className="flex px-6">
                    <img src={Help} alt="Services" className="mr-2" />
                    
                    <Help className="mr-2 mt-2" />
                   
                    <a
                      href="/faq"
                      class="block py-2 pr-4 pl-3 text-lg text-white hover:text-textGreen md:hover:text-textGreen md:border-0 sm:hover:bg-hoverBg md:p-0"
                    >
                      FAQ
                    </a>
                  </div>
                  </button> */}
                </li>
                <li>
                <button
                    onClick={redirectContact}
                    class="block py-2 pr-4 text-lg pl-3 text-white drop2 hover:text-textGreen md:hover:text-textGreen md:border-0 md:hover:text-textGreen sm:hover:bg-hoverBg md:p-0"
                  >
                    <div className="flex">
                      {/* <img src={Services} alt="Services" className="mr-2" /> */}
                      <Contact className="mr-2" style={{marginTop:"6px"}}/>
                      Contact
                      {/* <img src={Drop} alt="Drop" className="ml-2 mt-1" /> */}
                    </div>
                    </button>
                  {/* <div className="flex drop2">
                    <img src={Contact} alt="Services" className="mr-2" />
                    <Contact className="mr-2 mt-1" />
                    <a
                      href="/contact"
                      class="block py-2 pr-4 pl-3 text-lg text-white hover:text-textGreen md:hover:text-textGreen md:border-0 sm:hover:bg-hoverBg md:p-0"
                    >
                      Contact
                    </a>
                  </div> */}
                </li>
              </ul>
            </div>
          </div>
        </nav>
      ) : (
        <nav class="fixed w-full z-20 px-2 sm:px-4 py-2.5 bg-black">
          <div class="flex flex-wrap justify-between items-center mx-auto">
            <a href="/" class="ml-4 flex items-center">
              <img src={CompanyLogo} class="mr-3 xs:h-9" />
              <span class="self-center text-xl font-semibold whitespace-nowrap"></span>
            </a>
            <div class="flex items-center md:order-2">
              <button
                type="button"
                class="flex mr-3 text-sm rounded-full md:mr-0 "
                // id="user-menu-button"
                aria-expanded="false"
                // data-dropdown-toggle="dropdown"
              >
                <ul className="hidden lg:flex flex-col lg:flex-row list-none lg:ml-auto">
                  <li className="nav-item">
                    <a
                      className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                      href=""
                    >
                      <img
                        src={Twitter}
                        className="mr-3 mt-1 h-6"
                        alt="Twitter"
                      />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                      href=""
                    >
                      <img
                        src={Instagram}
                        className="mr-3 h-6"
                        alt="Instagram"
                      />
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                      href=""
                    >
                      <img src={Facebook} className="mr-3 h-6" alt="Facebook" />
                    </a>
                  </li>
                </ul>
              </button>
              <div className="-mr-2 flex items-center md:hidden">
                <Popover>
                  <Popover.Button
                    className="mr-4 p-2 inline-flex items-center justify-center text-white"
                    onClick={navbarCloseHandler}
                  >
                    <span className="sr-only">Open main menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                    {/* <img src={Menu} alt="menu" className="h-6 w-6" /> */}
                  </Popover.Button>
                </Popover>
              </div>
            </div>
            <div
              class=" justify-between items-center w-full md:flex md:w-auto md:order-1"
              // id="mobile-menu-2"
            >
              <ul class="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
                <li>
                  <button
                    onClick={toggleDropdown}
                    class="ml-4 block py-2 pr-4 text-lg pl-3 drop text-white hover:text-textGreen md:hover:text-textGreen md:border-0 md:hover:text-textGreen sm:hover:bg-hoverBg md:p-0"
                  >
                    <div className="flex">
                      {/* <img
                        src={Services}
                        alt="Services"
                        className="mr-4"
                        style={{ marginLeft: "-12px" }}
                      /> */}
                      <Services className="mr-4 mt-2"
                        style={{ marginLeft: "-12px" }}/>
                      Services
                      {/* <img src={Drop} alt="Drop" className="ml-2" /> */}
                      <span className="drop">
                      <Drop2 className="ml-2 mt-3" />
                      </span>
                      
                    </div>
                    {isOpen && ( // if isOpen is true, show the dropdown
                      <Transition
                        show
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <div className="absolute z-20 w-72 top-24 mt-2 origin-top-right rounded-full shadow-lg">
                          <div
                            className="py-1 rounded-md shadow-xs iPhone"
                            style={{
                              backdropFilter: "blur(42.0842px)",
                              WebkitBackdropFilter: "blur(42.0842px)",
                              background: "rgba(82, 82, 82, 0.30)",
                            }}
                          >
                            <div className="flex pl-6 py-3 hover:bg-hoverState focus:outline-none focus:bg-hoverState transition ease-in-out duration-150">
                              <img src={Secure} alt="Services" />
                              <a
                                href="/services"
                                className="block px-4 py-3 text-lg leading-5 text-white whitespace-nowrap"
                              >
                                Securitizing NFTs
                              </a>
                            </div>
                            <div className="flex pl-6 py-4 hover:bg-hoverState focus:outline-none focus:bg-hoverState transition ease-in-out duration-150">
                              <img src={LitigationIcon} alt="Services" />
                              <a
                                href="/services/litigation"
                                className="block px-4 py-3 text-lg leading-5 text-white whitespace-nowrap"
                              >
                                Litigation Services
                              </a>
                            </div>
                            <div className="flex pl-6 py-4 hover:bg-hoverState focus:outline-none focus:bg-hoverState transition ease-in-out duration-150">
                              <img src={IntellectualIcon} alt="Services" />
                              <a
                                href="/services/intellectual"
                                className="block px-4 py-3 text-lg leading-5 text-white whitespace-nowrap"
                              >
                                Intellectual Property
                              </a>
                            </div>
                            <div className="flex pl-6 py-3 hover:bg-hoverState focus:outline-none focus:bg-hoverState transition ease-in-out duration-150">
                              <img src={BlockchainIcon} alt="Services" />
                              <a
                                href="/services/blockchain"
                                className="block px-4 py-3 text-lg leading-5 text-white whitespace-nowrap"
                              >
                                Blockchain / DAO
                              </a>
                            </div>
                            <div className="flex pl-6 py-3 hover:bg-hoverState focus:outline-none focus:bg-hoverState transition ease-in-out duration-150">
                              <img src={MetaverseIcon} alt="Services" />
                              <a
                                href="/services/metaverse"
                                className="block px-4 py-3 text-lg leading-5 text-white whitespace-nowrap"
                              >
                                Metaverse / VR Land
                              </a>
                            </div>
                            <div className="flex pl-6 hover:bg-hoverState focus:outline-none focus:bg-hoverState transition ease-in-out duration-150">
                              <img src={CertificationIcon} alt="Services" />
                              <a
                                href="/services/certification"
                                className="block px-4 pt-4 pb-6 text-lg leading-5 text-white whitespace-nowrap"
                              >
                                Certification Services
                              </a>
                            </div>
                          </div>
                        </div>
                      </Transition>
                    )}
                  </button>
                </li>
                <li>
                  <div className="ml-4 flex drop">
                    {/* <img src={Help} alt="Services" className="mr-2" /> */}
                    <Help className="mr-2 mt-4" />
                    <a
                      href="/faq"
                      class=" block py-2 pr-4 pl-3 text-lg text-white hover:text-textGreen md:hover:text-textGreen md:border-0 sm:hover:bg-hoverBg md:p-0"
                    >
                      FAQ
                    </a>
                  </div>
                </li>
                <li>
                  <div className="ml-4  flex drop2">
                    {/* <img src={Contact} alt="Services" className="mr-2" /> */}
                    <Contact className="mr-2 mt-3" />
                    <a
                      href="/contact"
                      class="block py-2 pr-4 pl-3 text-lg text-white hover:text-textGreen md:hover:text-textGreen md:border-0 sm:hover:bg-hoverBg md:p-0"
                    >
                      Contact
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      )}
    </>
  );
}
