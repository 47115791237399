import axios from "axios";
import { load } from "recaptcha-v3";

export const client = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: false,
});

export const recaptchaClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: false,
});

recaptchaClient.interceptors.request.use(async (config) => {
  try {
    const recaptcha = await load(process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY);
    const token = await recaptcha.execute("auth");

    config.data.recaptcha_token = token;
    const apiToken = localStorage.getItem("apiToken");
    if (apiToken && !config.headers?.Authorization) {
      config.headers.Authorization = `Bearer ${apiToken}`;
    }
    return config;
  } catch (err) {
    return Promise.reject(err);
  }
});

client.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("apiToken");
    if (token && !config.headers?.Authorization) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);
