import React from "react";
import "./styles/faq.css"

export default function NewsCard(props) {
  return (
    // <div className='my-10 mr-8  bg-cardColor border-white rounded-xl'>
    // <div class="max-w-sm rounded">
    //   <a href={props.article_url}>
    //     <img src={props.image_url} alt="Sunset in the mountains" width={300} />
    //     <div class="px-6 py-4">
    //       <div class="text-xl text-white mb-12 mt-4">{props.headline}</div>
    //     </div>
    //   </a>
    // </div>
    // </div>
    <div class="news rounded-lg overflow-hidden shadow-lg mb-16 bg-cardColor border-borderColor border-t-0 border">
      <a href={props.article_url} className="" target="_blank">
        <img class="news-img object-cover" src={props.image_url} alt="News"/>

        <div class="lg:px-6 lg:py-4 lg:pb-16 text-white rounded-b-lg xs:p-1 xs:pl-2">
          <p class="text-white lg:text-lg sm:text-xs xs:text-sm xs:mt-3 md:text-base lg:mt-0">{props.headline}</p>
          
        </div>
      </a>
    </div>
  );
}
