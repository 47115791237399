import { React } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ServicesPage from "../components/ServicesPage";

const service = [
  "DAO formation",
  "Wyoming DAO LLC",
  "Blockchain related ltigation",
  "DMCA takedown notices",
];

const benefit = [
  "Brand licenses",
  "Licensing Agreements",
  "Metaverse assets",
  "End user agreements",
];
export default function Services() {
  return (
    <>
      <Navbar />
      <ServicesPage
        heading1="METAVERSE /"
        heading2="VIRTUAL LANDATION"
        services={service}
        benefits={benefit}
      />
      <Footer />
    </>
  );
}
