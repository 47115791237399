import { Fragment, useState, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import Security from "../images/Security.png";
import Logo from "../images/CompanyLogo.png";
import Herobg from "../images/Herobg.png";
import Facebook from "../images/Facebook.svg";
import Twitter from "../images/Twitter.svg";
import Discord from "../images/Discord.svg";
import Instagram from "../images/Instagram.svg";
import Aos from "aos";
import "aos/dist/aos.css";
import Services from "../images/Services.svg";
import Help from "../images/help.svg";
import Contact from "../images/contact.svg";
import Drop from "../images/drop.svg";
import Menu from "../images/Menu.svg";
import Secure from "../images/Secure.svg";
import CertificationIcon from "../images/CertificationIcon.svg";
import MetaverseIcon from "../images/MetaverseIcon.svg";
import IntellectualIcon from "../images/IntellectualIcon.svg";
import LitigationIcon from "../images/LitigationIcon.svg";
import BlockchainIcon from "../images/BlockchainIcon.svg";
import Navbar from "./Navbar";
import Sec2 from "../images/Sec2.png";
import emailjs from "emailjs-com";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const navigation = [
  // { name: "Services", href: "/services" },
  { name: "FAQ", href: "/faq", icon: Help },
  { name: "Contact Us", href: "/contact", icon: Contact },
];

export default function Hero() {
  const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
  const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
  const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  console.log(SERVICE_ID);
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { name, email, message };
    console.log(data);
    try {
      emailjs
        .sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY)
        .then(
          (toast.success("Thank you for your message!"),
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        )
        .then((result) => {
          console.log(result);
          setEmail("");
          setMessage("");
          setName("");
        });
    } catch (err) {
      toast.error(err, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  return (
    <>
      <div className="hero-bg" style={{ backgroundImage: `url(${Herobg})` }}>
        {/* <Navbar className="mb-12 fixed" /> */}
        <div className="relative mb-8 ">
          <div className="max-w-full lg:ml-20 lg:mt-64">
            <div className="relative z-10 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-2/3 xl:pb-65 lg:pb-64 ">
              <main className="lg:mt-24 xs:mt-32 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-48 lg:mt-32 md:mt-40">
                <div className=" lg:text-left md:mt-24">
                  <h1 className="text-4xl font-bold tracking-wide text-shadow text-white sm:text-2xl md:text-6xl xs:text-4xl xs:ml-4">
                    <span className="block xl:inline">LEGAL SERVICES</span>{" "}
                    <span className="lg:mt-2 xs:mt-0 block ">FOR WEB 3.0</span>
                  </h1>
                  <div className="lg:unset md:unset xs:flex">
                    <img
                      src={Sec2}
                      alt="Services"
                      className="lg:hidden md:hidden sm:hidden xs:mr-2 xs:h-20 xs:mt-7"
                    />
                    <p className="text-base text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl md:mx-4 lg:mx-0 lg:mt-10 xs:text-base xs:mt-8">
                      <b>Enforceable</b> helps brands protect and enforce IP
                      rights in the metaverse and NFT marketplaces.
                    </p>
                  </div>
                  <div className="relative lg:mt-12 sm:mt-8 sm:flex sm:justify-center  xs:justify-center lg:justify-start ">
                    <div className="flex mt-3 sm:mt-0 sm:ml-3 w-full h-16  xs:justify-center xs:flex">
                      <img
                        src={Security}
                        alt="icons"
                        className="mt-4 mr-4 lg:h-24 xs:h-16 sm:h-12 sm:hidden xs:hidden lg:flex"
                        style={{ marginLeft: "-20px" }}
                      />
                      <div
                        className="mt-6 lg:w-96 md:w-96 xs:w-96 xs:h-12"
                        style={{ zIndex: "-100" }}
                      >
                        <a
                          href="/faq"
                          className=" w-full xs:py-4 xs:mt-0 sm:mt-0  flex items-center hover:bg-white justify-center lg:px-24 lg:py-4 lg:mt-3  border-transparent text-base font-semibold rounded-md text-white md:py-8 sm:py-4 md:text-base md:px-10 xs:text-sm xs:px-16 xs:mt-2"
                          style={{
                            background: "#21A878",
                            borderRadius: "51.4674px",
                            zIndex: "100",
                          }}
                        >
                          WHY ENFORCEABLE
                        </a>
                      </div>
                    </div>
                    <div className="xs:flex xs:flex-row xs:mt-8 lg:hidden md:hidden sm:hidden xs:flex xs:justify-center">
                      <a href="https://twitter.com/EnforceableNFT" target="_blank">
                      <img
                        src={Twitter}
                        alt="icons"
                        className="w-8 h-8 mt-4 mx-4 mb-4"
                      />
                      </a>
                      <a href="https://discord.gg/Zj3tcyxGRv" target="_blank">
                      <img
                        src={Discord}
                        alt="icons"
                        className="w-7 h-7 mx-8 my-4"
                      />
                      </a>
                      {/* <img
                        src={Instagram}
                        alt="icons"
                        className="w-7 h-7 mx-4 mt-4"
                      /> */}
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
          {/* <div className="xl:absolute lg:inset-y-0 lg:right-96 lg:w-3/8 lg:align-center lg:justify-center lg:flex">
        <div class="w-full md:w-96 mx-auto"> */}
          <div
            class="xl:absolute lg:inset-y-0  mx-auto card2 p-6 border border-borderColor sm:rounded-md"
            style={{ background: "rgba(0, 0, 0, 0.4)", marginTop: "-150px" }}
          >
            <form onSubmit={handleSubmit}>
              <a className="w-full flex items-center text-center justify-center lg:px-8 font-medium rounded-md text-textGreen sm:px-0 md:py-4 md:text-md md:px-10 md:text-xl sm:text-xl lg:text-2xl">
                Question for Enforceable?
              </a>
              <a className="text-help w-full flex mb-12 items-center text-center justify-center lg:px-8 sm:px-0 font-semibold rounded-md text-white md:py-4 sm:mt-10 md:text-md md:px-16 lg:text-xl">
                Let us see if we can help
              </a>
              <label class="block mb-6 p-2 px-12 mt-2 lg:px-12 xs:p-0">
                <span class="text-gray-300 text-lg ">
                  What question can we answer for you
                </span>
                <textarea
                  name="message"
                  type="text"
                  class="block w-full mt-1
                  border-2
            rounded-xl
            shadow-sm
            border-borderColor
            focus:border-white
            bg-transparent
            placeholder-gray-600
            text-white
            p-4
            resize-none
          "
                  rows="5"
                  placeholder="Tell us what you're thinking about..."
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                ></textarea>
              </label>
              <label class="block mb-8 p-2 px-12  lg:px-12 xs:p-0">
                <span class="text-gray-300 text-md ">Your name</span>
                <input
                  name="name"
                  type="text"
                  class="
                  block w-full mt-1
                  border-2
                  border-borderColor
                  rounded-full
                  shadow-sm
                  bg-transparent
                  placeholder-gray-600
                  text-white
                  p-4
          "
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </label>
              <label class="block mb-6 p-2 lg:px-12 xs:p-0">
                <span class="text-gray-300 text-lg">Email address</span>
                <input
                  name="email"
                  type="email"
                  class="
                  block w-full 
                  border-2
                  border-borderColor
                  rounded-full
                  shadow-sm
                  focus:border-white
                  bg-transparent
                  placeholder-gray-600
                  text-white
                  p-4
          "
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </label>

              <div class="m-2 p-2 px-6 flex items-center justify-center">
                <button
                  type="submit"
                  class="
            h-12
            lg:px-24
            text-black
            text-lg
            bg-white
            font-semibold
            rounded-3xl
            transition-colors
            duration-150
            focus:shadow-outline
            hover:bg-buttonGreen
            hover:text-white
            xs:px-16
                          
          "
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
          {/* <div
          className="lg:flex lg:flex-col absolute top-56 xs:hidden sm:hidden md:hidden"
          style={{ right: "2%" }}
        >
          <img src={Twitter} alt="icons" className="w-8 h-8 mb-4" />
          <img src={Facebook} alt="icons" className="w-8 h-8 my-4" />
          <img src={Instagram} alt="icons" className="w-8 h-8 mt-4" />
        </div> */}
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
