import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Fragment, useState } from "react";
import Logo from "../images/CompanyLogo.png";
import Ethereum from "../images/Ethereum.png";
import Immutable from "../images/Immutable.png";
import Polygon from "../images/Polygon.png";
import Sections from "../images/Sectionsbg.png";
import Solana from "../images/Solana.png";
import NFT from "../images/NFT.svg";
import Facebook from "../images/Facebook.svg";
import Twitter from "../images/Twitter.svg";
import Instagram from "../images/Instagram.svg";
import Services from "../images/Services.svg";
import Help from "../images/help.svg";
import Contact from "../images/contact.svg";
import Drop from "../images/drop.svg";
import Secure from "../images/Secure.svg";
import CertificationIcon from "../images/CertificationIcon.svg";
import MetaverseIcon from "../images/MetaverseIcon.svg";
import IntellectualIcon from "../images/IntellectualIcon.svg";
import LitigationIcon from "../images/LitigationIcon.svg";
import emailjs from "emailjs-com";
import BlockchainIcon from "../images/BlockchainIcon.svg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Bg2 from "../images/Bg2.png";

const navigation = [
  // { name: "Services", href: "/services" },
  { name: "FAQ", href: "/faq", icon: Help },
  { name: "Contact Us", href: "/contact", icon: Contact },
];

export default function ServicesPage(props) {
  const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
  const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
  const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  console.log(SERVICE_ID)
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { name, email, message };
    console.log(data);
    console.log(data);
    try {
        emailjs
          .sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY)
          .then(
            (toast.success("Thank you for your message!"),
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          )
          .then((result) => {
            console.log(result);
            setEmail("");
            setMessage("");
            setName("");
          });
      } catch (err) {
        toast.error(err, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const data = { name, email, message };
  //   console.log(data);
  // };
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () =>{
     if(window.scrollY >= 80){
       setColorchange(true);
     }
     else{
       setColorchange(false);
     }
    }
    window.addEventListener('scroll', changeNavbarColor);
  return (
    <>
    <div
      className="relative sections-img"
      // style={{ backgroundImage: `url(${Sections})` }}
    >
      <div className="max-w-full lg:mt-64 xs:mt-20 lg:ml-20 mb-24">
        <div className="relative z-10 pb-8 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-2/3 lg:pb-28 xl:pb-32">
          <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-48 lg:mt-32">
            <div className="sm:text-center lg:text-left">
              <h1 className="text-4xl tracking-wider font-semibold text-white lg:py-0 lg:pt-0 sm:text-5xl md:text-6xl xs:text-3xl xs:py-4 xs:pt-8">
                <span className="block xl:inline text-shadow">
                  {props.heading1}
                </span>{" "}
                <span className="mt-2 block text-shadow">{props.heading2}</span>
              </h1>

              <p className="text-base mb-4 text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-autolg:py-0  md:mt-5 md:text-2xl lg:mx-0 lg:mt-12 xs:text-md xs:py-2 xs:mt-6">
              <b>Enforceable</b> offers the following services
              </p>
              <div className="align-center">
                {props.services.map((item) => (
                  <li
                    key={item}
                    className="font-medium text-white lg:text-xl align-start xs:text-sm"
                  >
                    {item}
                  </li>
                ))}
              </div>
              <div>
                <p className="text-base text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-lg lg:mx-0 lg:mt-24 xs:pt-12">
                  ENFORCEABLE ACCEPTS LEGAL FEES IN CRYPTOCURRECY
                </p>
                <div
                  className="flex flex-row  items-center justify-around py-6"
                  style={{ marginLeft: "-11.5%" }}
                >
                  <img
                    src={Ethereum}
                    className="lg:w-48 xs:w-32 h-auto py-4"
                    alt="Ethereum"
                  />
                  <img
                    src={Solana}
                    className="lg:w-48  xs:w-32 h-auto py-4"
                    alt="Solana"
                  />
                </div>
                <div
                  className="flex flex-row  items-center justify-around py-6"
                  style={{ marginLeft: "-11.5%" }}
                >
                  <img
                    src={Polygon}
                    className="lg:w-48  xs:w-32 h-auto py-4"
                    alt="Polygon"
                  />
                  <img
                    src={Immutable}
                    className="lg:w-48  xs:w-32 h-auto py-4"
                    alt="Immutable"
                  />
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="xl:absolute lg:inset-y-0 lg:right-96 lg:w-3/8 lg:align-center lg:justify-center lg:flex">
        <div class="lg:w-full md:w-full mx-auto mr-24">
          <div
            class="card p-6 border border-borderColor sm:rounded-md"
            style={{ background: "rgba(0, 0, 0, 0.29)", marginTop:"100px" }}
          >
            <form onSubmit={handleSubmit}>
              <a className="w-full flex items-center justify-center px-8 font-semibold rounded-md text-textGreen md:py-4 md:text-md md:px-10 lg:text-lg">
                Why Enforceable ?
              </a>
              <div className="flex w-full flex items-center justify-center text-help">
                <img src={NFT} alt="NFT" className="lg:w-24 lg:h-20 xs:h-16 xs:w-16 mt-1" />
                <a className="font-semibold rounded-md text-white md:py-4 md:text-md md:ml-4 lg:text-4xl xs:mt-1 lg:mt-0 xs:ml-2">
                  Easy as 1, 2, 3
                </a>
              </div>
              <a className="w-full flex items-center justify-center px-8 py-3 text-center font-medium rounded-md text-white md:py-4 md:text-md md:px-10 lg:text-lg">
                Enforceable is the premiere Web3 litigation law firm, we have
                deep experience in all areas of Web3.
              </a>
              <div className="mb-16 mt-4">
                {props.benefits.map((item) => (
                  <a
                    className="w-full flex items-center justify-center px-8 py-3 text-center font-medium rounded-md text-white md:py-4 md:text-md md:px-10 lg:text-2xl"
                    key={item}
                  >
                    {item}
                  </a>
                ))}
              </div>

              <label class="block mb-6 p-2 px-12  lg:px-12 xs:p-0">
                <span class="text-gray-300 lg:text-lg xs:text-sm ">
                  What question can we answer for you
                </span>
                <textarea
                  name="message"
                  type="text"
                  class="block w-full mt-1
                  border-2
            rounded-lg
            shadow-sm
            focus:border-white
            border-borderColor
            focus:ring
            focus:ring-white
            focus:ring-white
            bg-transparent
            placeholder-gray-600
            text-white
            p-4
            resize-none
          "
                  rows="5"
                  placeholder="Tell us what you're thinking about..."
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                ></textarea>
              </label>
              <label class="block mb-8 p-2 px-12  lg:px-12 xs:p-0">
                <span class="text-gray-300 text-md ">Your name</span>
                <input
                  name="name"
                  type="text"
                  class="
                  block w-full mt-1
                  border-2
                  rounded-full
                  border-borderColor
                  shadow-sm
                  focus:border-white
                  focus:ring
                  focus:ring-white
                  bg-transparent
                  placeholder-gray-600
                  text-white
                  p-4
          "
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </label>
              <label class="block mb-6 p-2 lg:px-12 xs:p-0">
                <span class="text-gray-300 text-lg">Email address</span>
                <input
                  name="email"
                  type="email"
                  class="
                  block w-full 
                  border-2
                  border-borderColor
                  rounded-full
                  shadow-sm
                  focus:border-white
                  focus:ring
                  focus:ring-white
                  focus:ring-white
                  bg-transparent
                  placeholder-gray-600
                  text-white
                  p-4
          "
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </label>

              <div class="mb-4 p-2 px-6 flex items-center justify-center">
              <button
                type="submit"
                class="
            h-12
            lg:px-24
            text-white
            text-lg
            bg-buttonGreen
            font-semibold
            rounded-3xl
            transition-colors
            duration-150
            focus:shadow-outline
            hover:bg-white
            hover:text-black
            xs:px-16
                          
          "
              >
                REQUEST
              </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* <div
        className="lg:flex lg:flex-col absolute top-72 xs:hidden sm:hidden md:hidden"
        style={{ right: "1%" }}
      >
        <img src={Twitter} alt="icons" className="w-8 h-8 mb-4" />
        <img src={Facebook} alt="icons" className="w-8 h-8 my-4" />
        <img src={Instagram} alt="icons" className="w-8 h-8 mt-4" />
      </div> */}
    </div>
    <ToastContainer />
    </>
  );
}
