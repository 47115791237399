import { React } from "react";
import Footer from "../components/Footer";
import ServicesPage from "../components/ServicesPage";
import Navbar from "../components/Navbar";


const service = ["Patents"," Trademarks","Trade Secrets","Coprights","Protection + Enforcement","International"];

const benefit = [
  "13 years in business",
  "Started by IP Lawyers",
  "300 + crypto/NFT clients",
  "Litigation in federal & state",
];

export default function Services() {
  return (
    <>
    <Navbar />
      <ServicesPage heading1= "INTELLECTUAL" heading2= "PROPERTY" services={service} benefits={benefit}/>
      <Footer />
    </>
  );
}
