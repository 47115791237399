import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Fragment, useState } from "react";
import Logo from "../images/CompanyLogo.png";
import Ethereum from "../images/Ethereum.png";
import Immutable from "../images/Immutable.png";
import Polygon from "../images/Polygon.png";
import Sections from "../images/Sectionsbg.png";
import Solana from "../images/Solana.png";
import NFT from "../images/NFT.svg";
import Facebook from "../images/Facebook.svg";
import Twitter from "../images/Twitter.svg";
import Instagram from "../images/Instagram.svg";
import Services from "../images/Services.svg";
import Help from "../images/help.svg";
import Contact from "../images/contact.svg";
import Drop from "../images/drop.svg";
import Secure from "../images/Secure.svg";
import CertificationIcon from "../images/CertificationIcon.svg";
import MetaverseIcon from "../images/MetaverseIcon.svg";
import IntellectualIcon from "../images/IntellectualIcon.svg";
import LitigationIcon from "../images/LitigationIcon.svg";
import BlockchainIcon from "../images/BlockchainIcon.svg";
import No1 from "../images/No1.svg";
import No2 from "../images/No2.svg";
import No3 from "../images/No3.svg";
import emailjs from "emailjs-com";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const navigation = [
  // { name: "Services", href: "/services" },
  { name: "FAQ", href: "/faq", icon: Help },
  { name: "Contact Us", href: "/contact", icon: Contact },
];

export default function ServicesPage(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
  const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
  const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { name, email, message };
    console.log(data);
    console.log(data);
    try {
        emailjs
          .sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY)
          .then(
            (toast.success("Thank you for your message!"),
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          )
          .then((result) => {
            console.log(result);
            setEmail("");
            setMessage("");
            setName("");
          });
      } catch (err) {
        toast.error(err, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  return (
    <>
    <div
      className="relative sections-img"
    //   style={{ backgroundImage: `url(${Sections})` }}
    >
        <div className="lg:mt-64 xs:mt-20">
      <div className="max-w-full lg:ml-20 mb-24">
        <div className="relative z-10 pb-8 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-2/3 lg:pb-32 xl:pb-72">
          {/* <Popover>
            <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
              <nav
                className={colorChange ? "p-2 pl-4 nav colorChange" : "nav"}
                aria-label="Global"
              >
                <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                  <div className="flex items-center justify-between w-full md:w-auto">
                    <a href="/">
                      <img
                        alt="Enforceable"
                        className="h-12 w-auto sm:h-10 lg:h-12 lg:w-auto"
                        src={Logo}
                      />
                    </a>
                    <div className="-mr-2 flex items-center md:hidden">
                      <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-white">
                        <span className="sr-only">Open main menu</span>
                        <MenuIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                </div>
                <div className="sm:hidden md:flex xs:hidden md:block md:ml-48 md:pr-12 md:space-x-8">
                  <button
                    className="font-medium text-white hover:text-textGreen"
                    onClick={toggleDropdown}
                  >
                    <div className="flex">
                      <img src={Services} alt="Services" className="mr-2" />
                      Services
                      <img src={Drop} alt="Drop" className="ml-2" />
                      {isOpen && ( // if isOpen is true, show the dropdown
                        <Transition
                          show
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <div className="absolute z-10 w-72 left-72 top-12 mt-2 origin-top-right rounded-md shadow-lg">
                            <div
                              className="py-1 rounded-md shadow-xs"
                              style={{
                                backdropFilter: "blur(42.0842px)",
                                background: "rgba(82, 82, 82, 0.24)",
                              }}
                            >
                              <div className="flex pl-6 hover:bg-hoverState focus:outline-none focus:bg-hoverState transition ease-in-out duration-150">
                                <img src={Secure} alt="Services" />
                                <a
                                  href="/services"
                                  className="block px-4 py-4 text-lg leading-5 text-white whitespace-nowrap"
                                >
                                  Securitizing NFTs
                                </a>
                              </div>
                              <div className="flex pl-6 hover:bg-hoverState focus:outline-none focus:bg-hoverState transition ease-in-out duration-150">
                                <img src={LitigationIcon} alt="Services" />
                                <a
                                  href="/services/litigation"
                                  className="block px-4 py-4 text-lg leading-5 text-white whitespace-nowrap"
                                >
                                  Litigation Services
                                </a>
                              </div>
                              <div className="flex pl-6 hover:bg-hoverState focus:outline-none focus:bg-hoverState transition ease-in-out duration-150">
                                <img src={IntellectualIcon} alt="Services" />
                                <a
                                  href="/services/intellectual"
                                  className="block px-4 py-4 text-lg leading-5 text-white whitespace-nowrap"
                                >
                                  Intellectual Property
                                </a>
                              </div>
                              <div className="flex pl-6 hover:bg-hoverState focus:outline-none focus:bg-hoverState transition ease-in-out duration-150">
                                <img src={BlockchainIcon} alt="Services" />
                                <a
                                  href="/services/blockchain"
                                  className="block px-4 py-4 text-lg leading-5 text-white whitespace-nowrap"
                                >
                                  Blockchain / DAO
                                </a>
                              </div>
                              <div className="flex pl-6 hover:bg-hoverState focus:outline-none focus:bg-hoverState transition ease-in-out duration-150">
                                <img src={MetaverseIcon} alt="Services" />
                                <a
                                  href="/services/metaverse"
                                  className="block px-4 py-4 text-lg leading-5 text-white whitespace-nowrap"
                                >
                                  Metaverse / VR Land
                                </a>
                              </div>
                              <div className="flex pl-6 hover:bg-hoverState focus:outline-none focus:bg-hoverState transition ease-in-out duration-150">
                                <img src={CertificationIcon} alt="Services" />
                                <a
                                  href="/services/certification"
                                  className="block px-4 py-4 text-lg leading-5 text-white whitespace-nowrap"
                                >
                                  Certification Services
                                </a>
                              </div>
                            </div>
                          </div>
                        </Transition>
                      )}
                    </div>
                  </button>

                  {navigation.map((item) => (
                    <div className="flex">
                      <img src={item.icon} alt={item.name} className="mr-2" />
                      <a
                        key={item.name}
                        href={item.href}
                        className="font-medium text-white hover:text-textGreen"
                      >
                        {item.name}
                      </a>
                    </div>
                  ))}
                </div>
              </nav>
            </div>

            <Transition
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                className="absolute z-10 top-0 inset-x-0 p-2 bg-black transition transform origin-top-right md:hidden"
              >
                <div className="rounded-lg shadow-md ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="px-5 pt-4 flex items-center justify-between">
                    <div>
                      <img className="h-12 w-auto" src={Logo} alt="" />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="bg-black rounded-md p-2 inline-flex items-center justify-center text-white">
                        <span className="sr-only">Close main menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="px-2 pt-2 pb-3 space-y-1">
                    <button
                      className="font-medium text-white hover:text-textGreen ml-3"
                      onClick={toggleDropdown}
                    >
                      Services
                      {isOpen && ( // if isOpen is true, show the dropdown
                        <Transition
                          show
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <div className="absolute z-20 w-72 mt-2 origin-top-right rounded-md shadow-lg">
                            <div
                              className="py-1 rounded-md shadow-xs"
                              style={{
                                backdropFilter: "blur(42.0842px)",
                                background: "rgba(82, 82, 82, 0.24)",
                              }}
                            >
                              <div className="flex pl-6 hover:bg-hoverState focus:outline-none focus:bg-hoverState transition ease-in-out duration-150">
                                <img src={Secure} alt="Services" />
                                <a
                                  href="/services"
                                  className="block px-4 py-4 text-lg leading-5 text-white whitespace-nowrap"
                                >
                                  Securitizing NFTs
                                </a>
                              </div>
                              <div className="flex pl-6 hover:bg-hoverState focus:outline-none focus:bg-hoverState transition ease-in-out duration-150">
                                <img src={LitigationIcon} alt="Services" />
                                <a
                                  href="/services/litigation"
                                  className="block px-4 py-4 text-lg leading-5 text-white whitespace-nowrap"
                                >
                                  Litigation Services
                                </a>
                              </div>
                              <div className="flex pl-6 hover:bg-hoverState focus:outline-none focus:bg-hoverState transition ease-in-out duration-150">
                                <img src={IntellectualIcon} alt="Services" />
                                <a
                                  href="/services/intellectual"
                                  className="block px-4 py-4 text-lg leading-5 text-white whitespace-nowrap"
                                >
                                  Intellectual Property
                                </a>
                              </div>
                              <div className="flex pl-6 hover:bg-hoverState focus:outline-none focus:bg-hoverState transition ease-in-out duration-150">
                                <img src={BlockchainIcon} alt="Services" />
                                <a
                                  href="/services/blockchain"
                                  className="block px-4 py-4 text-lg leading-5 text-white whitespace-nowrap"
                                >
                                  Blockchain / DAO
                                </a>
                              </div>
                              <div className="flex pl-6 hover:bg-hoverState focus:outline-none focus:bg-hoverState transition ease-in-out duration-150">
                                <img src={MetaverseIcon} alt="Services" />
                                <a
                                  href="/services/metaverse"
                                  className="block px-4 py-4 text-lg leading-5 text-white whitespace-nowrap"
                                >
                                  Metaverse / VR Land
                                </a>
                              </div>
                              <div className="flex pl-6 hover:bg-hoverState focus:outline-none focus:bg-hoverState transition ease-in-out duration-150">
                                <img src={CertificationIcon} alt="Services" />
                                <a
                                  href="/services/certification"
                                  className="block px-4 py-4 text-lg leading-5 text-white whitespace-nowrap"
                                >
                                  Certification Services
                                </a>
                              </div>
                            </div>
                          </div>
                        </Transition>
                      )}
                    </button>
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="block px-3 py-2 rounded-md text-base font-medium text-white hover:text-white hover:bg-hoverState"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover> */}

          <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-48 lg:mt-32">
            <div className="sm:text-center lg:text-left">
              <h1 className="text-4xl tracking-wider font-semibold text-white lg:py-0 lg:pt-0 sm:text-5xl md:text-6xl xs:text-3xl xs:py-4 xs:pt-8">
                <span className="block xl:inline text-shadow leading-tight">
                  {props.heading1}
                </span>{" "}
                <span className="mt-2 block text-shadow ">{props.heading2}</span>
              </h1>

              <p className="text-base mb-4 text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-autolg:py-0  md:mt-5 md:text-2xl lg:mx-0 lg:mt-12 xs:text-md xs:py-2 xs:mt-6">
                <b>Enforceable</b> offers the following services
              </p>
              <div className="align-center">
                {props.services.map((item) => (
                  <li
                    key={item}
                    className="font-medium text-white lg:text-xl align-start xs:text-sm"
                  >
                    {item}
                  </li>
                ))}
              </div>
              <div>
              <p className="text-base text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-lg lg:mx-0 lg:mt-24 xs:pt-12">
                  ENFORCEABLE ACCEPTS LEGAL FEES IN CRYPTOCURRECY
                </p>
                <div
                  className="flex flex-row  items-center justify-around py-6"
                  style={{ marginLeft: "-11.5%" }}
                >
                  <img
                    src={Ethereum}
                    className="lg:w-48 xs:w-32 h-auto py-4"
                    alt="Ethereum"
                  />
                  <img
                    src={Solana}
                    className="lg:w-48  xs:w-32 h-auto py-4"
                    alt="Solana"
                  />
                </div>
                <div
                  className="flex flex-row  items-center justify-around py-6"
                  style={{ marginLeft: "-11.5%" }}
                >
                  <img
                    src={Polygon}
                    className="lg:w-48  xs:w-32 h-auto py-4"
                    alt="Polygon"
                  />
                  <img
                    src={Immutable}
                    className="lg:w-48  xs:w-32 h-auto py-4"
                    alt="Immutable"
                  />
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="xl:absolute lg:inset-y-0 lg:right-96 lg:w-3/8 lg:align-center lg:justify-center lg:flex">
        <div class="lg:w-full md:w-full mx-auto mr-24">
          <div
            class="card p-6 border border-borderColor sm:rounded-md"
            style={{ background: "rgba(0, 0, 0, 0.29)", marginTop:"100px" }}
          >
            <form onSubmit={handleSubmit}>
              <a className="w-full flex items-center justify-center px-8 font-semibold rounded-md text-textGreen md:py-4 md:text-md md:px-10 lg:text-lg whitespace-nowrap xs:text-sm">
                Certification Services by Enforceable
              </a>
              <div className="flex w-full flex items-center justify-center text-help">
              <img src={NFT} alt="NFT" className="lg:w-24 lg:h-20 xs:h-16 xs:w-16 mt-1" />
                <a className="font-semibold rounded-md text-white md:py-4 md:text-md md:ml-4 lg:text-4xl xs:mt-1 lg:mt-0 xs:ml-2">
                  Easy as 1, 2, 3
                </a>
              </div>
              {/* <a className="w-full flex items-center justify-center px-8 py-3 text-center font-medium rounded-md text-white md:py-4 md:text-md md:px-10 lg:text-lg">
                Enforceable is the premiere Web3 litigation law firm, we have
                deep experience in all areas of Web3.
              </a> */}
              <div className="flex lg:mt-10 lg:ml-16 xs:ml-4 mr-8 xs:mt-6">
                <img
                  src={No1}
                  alt="No1"
                  className="lg:w-32 lg:h-32 xs:w-12 numbers xs:h-auto lg:ml-4 lg:mr-8 xs:mr-4"
                />

                <div className="text-left xs:mr-0 xs:mt-5">
                  <a className="font-semibold rounded-md text-white md:py-4 md:text-md lg:text-3xl">
                  You Declare
                  </a>
                  {/* Sub heading */}
                  <p className="lg:mt-2 lg:text-base lg:text-sm text-white text-left flex-wrap xs:text-xs">
                  We will help you demonstrate that you hold the IP rights you wish to assign.
                  </p>

                </div>
              </div>
              <div className="flex mt-10 lg:ml-16 xs:ml-4 mr-8">
                <img
                  src={No2}
                  alt="No1"
                  className="lg:w-32 lg:h-32 xs:w-12 numbers xs:h-auto lg:mr-6 xs:mr-4"
                />

                <div className="text-left xs:mr-0 xs:mt-5">
                  <a className="font-semibold rounded-md text-white md:py-4 md:text-md lg:text-3xl">
                  We Prepare
                  </a>
                  {/* Sub heading */}
                  <p className="lg:mt-2 lg:text-base lg:text-sm text-white text-left flex-wrap xs:text-xs">
                  We prepare the legal documents and smart contracts.
                  </p>

                </div>
              </div><div className="flex mt-10 lg:ml-16 xs:ml-4 mb-16 mr-8">
                <img
                  src={No3}
                  alt="No1"
                  className="lg:w-32 lg:h-32 xs:w-12 numbers xs:h-auto lg:mr-6 xs:mr-4"
                />

                <div className="text-left xs:mr-0 xs:mt-5">
                  <a className="font-semibold rounded-md text-white md:py-4 md:text-md lg:text-3xl">
                  Merge with NFT
                  </a>
                  {/* Sub heading */}
                  <p className="lg:mt-2 lg:text-base lg:text-sm text-white text-left flex-wrap xs:text-xs">
                  The IP rights you wish to assign are merged with a new NFT.
                  </p>

                </div>
              </div>
              {/* <div className="mb-16 mt-4">
                {props.benefits.map((item) => (
                  <a
                    className="w-full flex items-center justify-center px-8 py-3 text-center font-medium rounded-md text-white md:py-4 md:text-md md:px-10 lg:text-2xl"
                    key={item}
                  >
                    {item}
                  </a>
                ))}
              </div> */}

              <label class="block mb-6 p-2 px-12  lg:px-12 xs:p-0">
                <span class="text-gray-300 lg:text-lg xs:text-sm ">
                  What question can we answer for you
                </span>
                <textarea
                  name="message"
                  type="text"
                  class="block w-full mt-1
                  border-2
            rounded-lg
            shadow-sm
            focus:border-white
            border-borderColor
            focus:ring
            focus:ring-white
            focus:ring-white
            bg-transparent
            placeholder-gray-600
            text-white
            p-4
            resize-none
          "
                  rows="5"
                  placeholder=""
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                ></textarea>
              </label>
              <label class="block mb-8 p-2 px-12  lg:px-12 xs:p-0">
                <span class="text-gray-300 lg:text-md xs:text-sm">Your name</span>
                <input
                  name="name"
                  type="text"
                  class="
                  block w-full mt-1
                  border-2
                  rounded-full
                  border-borderColor
                  shadow-sm
                  focus:border-white
                  focus:ring
                  focus:ring-white
                  bg-transparent
                  placeholder-gray-600
                  text-white
                  p-4
          "
                  placeholder=""
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </label>
              <label class="block mb-6 p-2 lg:px-12 xs:p-0">
                <span class="text-gray-300 lg:text-lg xs:text-sm">Email address</span>
                <input
                  name="email"
                  type="email"
                  class="
                  block w-full 
                  border-2
                  border-borderColor
                  rounded-full
                  shadow-sm
                  focus:border-white
                  focus:ring
                  focus:ring-white
                  focus:ring-white
                  bg-transparent
                  placeholder-gray-600
                  text-white
                  p-4
          "
                  placeholder=""
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </label>

              <div class="mb-4 p-2 px-6 flex items-center justify-center">
              <button
                type="submit"
                class="
            h-12
            lg:px-24
            text-white
            text-lg
            bg-buttonGreen
            font-semibold
            rounded-3xl
            transition-colors
            duration-150
            focus:shadow-outline
            hover:bg-white
            hover:text-black
            xs:px-16
            xs:text-base
                          
          "
              >
                REQUEST
              </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* <div
        className="lg:flex lg:flex-col absolute top-72 xs:hidden sm:hidden md:hidden"
        style={{ right: "1%" }}
      >
        <img src={Twitter} alt="icons" className="w-8 h-8 mb-4" />
        <img src={Facebook} alt="icons" className="w-8 h-8 my-4" />
        <img src={Instagram} alt="icons" className="w-8 h-8 mt-4" />
      </div> */}
      </div>
    </div>
    <ToastContainer />
    </>
  );
}
