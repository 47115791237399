import React from 'react'
import Vector from '../images/Vector.png'
import './styles/faq.css'
import enft from '../images/enft.png'
import Service2 from '../images/Service2.svg'
import Service3 from '../images/Service3.svg'
import Service4 from '../images/Service4.svg'
import Service5 from '../images/Service5.svg'
import Service6 from '../images/Service6.svg'

const features = [
    {
      name: 'SECURITIZING NFTS',
      description:
        'Creator declarations, merging NFTs with IP rights, forming LLC wrappers.',
        icon: Vector
    },
    {
        name: 'ENFORCING NFTS',
        description:
          'Creator declarations, merging NFTs with IP rights, forming LLC wrappers.',
        icon: Service2
      },
      {
        name: 'METAVERSE & VIRTUAL LAND',
        description:
          'Creator declarations, merging NFTs with IP rights, forming LLC wrappers.',
        icon: Service3
      },
      {
        name: 'INTELLECTUAL PROPERTY',
        description:
          'Creator declarations, merging NFTs with IP rights, forming LLC wrappers.',
        icon: Service4
      },
      {
        name: 'LITIGATION DISPUTES',
        description:
          'Creator declarations, merging NFTs with IP rights, forming LLC wrappers.',
        icon: Service5
      },
      {
        name: 'BLOCKCHAIN & DAO',
        description:
          'Creator declarations, merging NFTs with IP rights, forming LLC wrappers.',
        icon: Service6
      },
      
  ]

export default function ServicesSection() {
  return (
    <div className="py-12 lg:mt-32 xs:mt-12">
      <div className="lg:w-10/12 xs:w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:mb-24 xs:mb-12 ">
          <div className="flex align-center justify-start">
          <img className="lg:w-32 xs:w-24 lg:h-auto xs:h-auto" src={enft} alt="eNFT" />
          <p className="mt-7 xs:mt-6 ml-6 xs:ml-2 lg:text-3xl leading-8 font-extrabold lg:text-left tracking-wide text-white sm:text-lg xs:text-base xs:text-center">
          GIVES YOU CONTROL
          </p>
          </div>
          <p className="mt-4 max-w-2xl lg:text-xl lg:text-left text-lightText xs:text-sm xs:text-left">
          Enforceable offers a wide range of access that sets control on your NFTs the way you want it.
          </p>
        </div>
        

        <div className="mt-10">
          <dl className="gap-y-24 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-2">
            {features.map((feature) => (
                <div className='lg:mr-12 mb-4'>
              <div key={feature.name} className="hover-underline-animation relative pb-6 pt-4 hover:bg-gradient-to-t from-cardHover">
                <dt>
                  <div className="absolute flex items-center justify-center h-24 w-24 text-white">
                    {/* <feature.icon className="h-6 w-6" aria-hidden="true" /> */}
                    <img src={feature.icon}  className=" mt-8 lg:h-20 lg:w-auto sm:h-8 sm:w-8 xs:h-12 xs:w-12 xs:-mt-10 lg:-mt-2" aria-hidden="true" />
                  </div>
                  <p className="lg:ml-32 lg:text-xl leading-6 text-white font-semibold xs:text-base xs:ml-24">{feature.name}</p>
                </dt>
                <dd className="mt-2 lg:ml-32 text-base text-lightText lg:text-lg xs:text-sm xs:ml-24">{feature.description}</dd>
              </div>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
