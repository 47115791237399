import axios from "axios";
import { React, useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import NewsCard from "./NewsCard";
import E from "../images/E.svg";
import "react-multi-carousel/lib/styles.css";
import "./styles/faq.css"
// import CustomDot from "./CustomDot";

export default function NewsCarousel() {
  const url = "https://api.enforceable.com/api/v1/news/all";
  const [news, setNews] = useState([]);
  useEffect(() => {
    axios
      .get(url)
      .then((res) => {
        setNews(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  if (news.body == null) {
    return (
      <div className="flex mx-auto my-auto justify-center align-center  items-center">
        {/* <div
                className="
                animate-spin
                rounded-full
                h-32
                w-32
                border-t-2 border-b-2 border-white
              "
              ></div> */}
        <img
          className="animate-bounce h-16 w-16 mx-auto"
          src={E}
          alt="loading"
        />
      </div>
    );
  }
  const CustomDot = ({ onMove, index, onClick, active }) => {
    // onMove means if dragging or swiping in progress.
    // active is provided by this lib for checking if the item is active or not.
    return (
      <li
        className={"dot text-xl mx-1 text-white" + (active === true ? " active" : "")}
        onClick={() => onClick()}
      >
      </li>
    );
  };
  return (
    <div
      style={{
        position: "relative",
      }}
      className="lg:mb-32 xs:mb-0"
    >
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlaySpeed={3000}
        centerMode={false}
        className=""
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        customDot={<CustomDot/>}
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 4,
          },
          mobile: {
            breakpoint: {
              max: 800,
              min: 650,
            },
            items: 2,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 800,
            },
            items: 3,
          },
          small:{
            breakpoint: {
              max: 650,
              min: 400,
          },
          items: 1,
          partialVisibilityGutter: 180,
        },
          smaller:{
            breakpoint: {
              max: 400,
              min: 340,
          },
          items: 1,
          partialVisibilityGutter: 150,
        },
        smallest:{
          breakpoint: {
            max: 340,
            min: 0,
        },
        items: 1,
        partialVisibilityGutter: 80,
      },
        }}
        showDots
        sliderClass=""
        slidesToSlide={1}
        swipeable
        partialVisbile={true}
      >
        {news.body.map((news) => (
          <div className="lg:px-8">
            <NewsCard
              article_url={news.article_url}
              image_url={news.image_url}
              headline={news.headline}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
}
