import React from "react";
import Ethereum from "../images/Ethereum.png";
import Solana from "../images/Solana.png";
import Polygon from "../images/Polygon.png";
import Immutable from "../images/Immutable.png";
import HeaderImage from "../images/Header.png";
import "./styles/faq.css";

export default function HeaderSection() {
  return (
    <div
      className="relative header-img"
      style={{ backgroundImage: `url(${HeaderImage})` }}
    >
      <a className="w-full flex items-center justify-center lg:px-8 xs:px-0 py-2 font-medium tracking-wide rounded-md text-white md:py-0 md:text-md md:px-10 lg:text-2xl lg:pt-12 xs:text-center xs:text-sm xs:mb-2 xs:mt-4 lg:mt-0 ">
        ENFORCEABLE ACCEPTS LEGAL FEES IN CRYPTOCURRECY.
      </a>
      <div className="flex flex-row flex-wrap items-center justify-around lg:py-12 xs:py-8">
        <img src={Ethereum} className="lg:w-48 lg:mt-0 lg:mb-0 md:mt-0 md:mb-0  xs:w-36 xs:mb-8 xs:-mt-6" alt="Ethereum" />
        <img src={Solana} className="lg:w-48 lg:mt-0 lg:mb-0 md:mt-0 md:mb-0 xs:w-36 xs:mb-8 xs:-mt-6" alt="Solana" />
        <img src={Polygon} className="lg:w-48  xs:w-36 " alt="Polygon" />
        <img src={Immutable} className="lg:w-48  xs:w-36" alt="Immutable" />
      </div>
    </div>
  );
}
